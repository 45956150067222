import { defineComponent, unref, computed } from 'vue';
export default defineComponent({
  name: 'BatteryIcon',
  props: {
    percentage: {
      type: Number,
      default: 0,
      validator(val) {
        return val >= 0 && val <= 100;
      }
    }
  },
  setup(props) {
    const lowColor = 'red'; // '#9af5bc';
    const normalColor = '#2cc564';
    const color = computed(() => props.percentage < 20 ? lowColor : normalColor);
    const progressStyle = computed(() => ({
      width: props.percentage + '%',
      backgroundColor: unref(color)
    }));
    const borderStyle = computed(() => ({
      borderColor: unref(color)
    }));
    return {
      progressStyle,
      borderStyle
    };
  }
});